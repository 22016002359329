@import "@angular/material/theming";

@include mat-core();

$simulatorPalette: (
  50: #e9e3ec,
  100: #c8b9d0,
  200: #a38ab0,
  300: #7e5b90,
  400: #623879,
  500: $dark-primary-color,
  600: #3f1259,
  700: #370f4f,
  800: #2f0c45,
  900: #200633,
  A100: #b76cff,
  A200: #9e39ff,
  A400: #8506ff,
  A700: #7900ec,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

.msg-success {
  background-color: #28a745;
  color: white;
}

.msg-error {
  background-color: #b62c2c;
  color: white;
}

$simulator-primary: mat-palette($simulatorPalette);
$simulator-accent: mat-palette($simulatorPalette, A200, A100, A400);

$simulator-warn: mat-palette($mat-red);

$simulator-theme: mat-light-theme(
  $simulator-primary,
  $simulator-accent,
  $simulator-warn
);

@include angular-material-theme($simulator-theme);

:root {
  --primaryColor: "#556a78";
  --primaryColorLight: "6a8496";
  --primaryColorDark: "#40505a";
  --secondaryColor: "#aadb1e";
  --secondaryColorLight: "#bce646";
  --secondaryColorDark: "#648112";
}
