@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

$enable-negative-margins: true;

@import "/node_modules/bootstrap/scss/bootstrap.scss";
@import "/src/assets/scss/stepper.scss";
@import "/src/assets/scss/inputs.scss";
@import "/src/assets/scss/theme.scss";
@import "/src/assets/scss/buttons.scss";

@import "/src/assets/scss/variables.scss";

* {
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
input,
button,
text-area {
  font-family: Poppins, sans-serif !important;
}

button:focus {
  outline: 0;
}

body {
  background-color: #f1f1f1;
}

.c-pointer {
  cursor: pointer;
}

.text-default {
  color: $default-text-color;
}

.text-gray-default {
  color: $default-gray;
}

.text-default-primary-color {
  color: $default-primary-color;
}

.text-dark-primary-color {
  color: $dark-primary-color;
}

div.mat-menu-content {
  max-width: 300px !important;
}

a {
  color: $default-text-color;

  &:hover {
    color: $default-primary-color;
    text-decoration: none;
  }
}

div.mat-horizontal-content-container {
  padding: 0;
}

input:disabled,
textarea:disabled {
  color: $default-text-color !important;
}

mat-tab-group:not(.completed)
  .mat-ripple:not(
    :first-child
  ):last-child.mat-tab-label.mat-focus-indicator.ng-star-inserted {
  color: $dark-primary-color;
  background: #f1f1f1;
  border-radius: 15px 15px 0 0;
}

app-broker-award-form,
app-consult-sales-form,
app-broker-my-register-form,
app-broker-company-my-register-form {
  div.mat-form-field-infix {
    display: flex !important;
    align-items: center;
    height: 56px;

    input {
      font-size: 16px;
    }

    button {
      margin-bottom: 5px;
    }
  }
}

.sale-component-title {
  font-size: 1rem;
  color: $default-text-color;

  @media screen and (max-width: 540px) {
    font-size: 0.9rem;
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $light-primary-color;
}

.a-tag-link-text {
  cursor: pointer;
  font-size: 13px;
  color: #0000ee;

  &:hover {
    color: #551a88;
    text-decoration: underline;
  }
}

.Swal__cancelButton {
  background-color: white !important;
  color: #595959 !important;
  box-shadow: inset 0 0 0 2px $light-gray !important;
  transition: background 0.5s;

  &:hover {
    background-color: $light-gray !important;
    background-image: none !important;
  }
}

.swal2-title {
  color: $default-primary-color !important;
}

app-input-field,
app-input-money {
  input.mat-input-element {
    font-size: 14px;
  }
}

mat-error {
  margin-bottom: 5px;
}

mat-error + mat-error {
  display: none !important;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 5px !important;
}

mat-form-field.ng-valid.ng-touched .mat-icon.material-icons {
  color: #198754;
}
app-age-ranges {
  div.mat-form-field-wrapper {
    @media screen and (max-width: 400px) {
      width: 61vw;
      margin-bottom: -20px;

      div.mat-form-field-infix {
        font-size: 12.5px !important;
      }
    }

    @media screen and (min-width: 401px) and (max-width: 540px) {
      width: 65vw;
    }
  }
}

.badge {
  font-weight: 600;
  text-transform: uppercase;
  background-color: #f1f1f1;
  padding: 5px;
}

.ans-tag {
  width: fit-content;
  background-color: #000;
  border: 2px solid #000;
}

.ans-content {
  padding: 0 4px;
  line-height: 0;
  white-space: nowrap;
  height: 16px;
  font-size: 8px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: Verdana;
  color: #fff !important;
  border: 1px solid #fff;
}

div.cdk-overlay-pane {
  max-width: 85vw !important;
}

.default-title-1,
.default-title-2,
.default-title-3,
.default-title-4 {
  color: $default-text-color;
}

.default-title-1 {
  font-size: 13pt;
}

.default-title-2 {
  font-size: 12pt;
}

.default-title-3 {
  font-size: 11pt;
}

.default-title-4 {
  font-size: 10pt;
}

.plan-row-title {
  color: $default-text-color;
}

.plan-row-text {
  color: $default-text-color;
  font-weight: 600;
}

table,
.p {
  font-size: 9pt;
}

div.mat-select-panel {
  max-width: 85vw;
}

span.mat-option-text,
.mat-select-value {
  font-size: 11px;
}

.raised-button-secondary {
  color: #fff;
  background-color: $default-secondary-color;
}

.mat-paginator-page-size {
  margin: 10px !important;
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: $default-secondary-color;
}

button.swal2-confirm.swal2-styled {
  background-color: $default-secondary-color;
}

// -> Personalização do mat-button
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: $default-secondary-color !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $default-secondary-color; /*inner circle color change*/
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $default-secondary-color; /*outer ring color change*/
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $default-text-color;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $light-gray;
}

.mat-mini-fab.mat-primary {
  background-color: #022840;
}

.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}

.description-label {
  color: $default-gray;
}

.pl-15 {
  padding-left: 15px;
}

.rotate-180 {
  transform: rotate(180deg);
}

div.mat-form-field-infix {
  font-size: 13px !important;
}

span.mat-select-min-line {
  color: $default-text-color;
}

app-button {
  display: block;
  width: fit-content;
}

.toast-message {
  font-size: 13px;
}

.toast-container {
  position: fixed !important;
  top: 12px !important;
}

.main-container:not(app-broker-area) {
  margin: 15px auto;
  padding: 0 !important;
  margin: 0 !important;
  max-width: none !important;

  flex-grow: 1;

  display: flex;
  justify-content: center;

  > * {
    flex: 1;
  }

  > router-outlet ~ *:not(app-broker-area) {
    width: 100%;
    max-width: 1700px;
  }

  app-client-proposal-form,
  app-proponent-form {
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0 !important;
    }
  }
}

app-login,
app-reset-pass,
app-logout,
app-request-pass,
app-auth,
app-create-user {
  width: 100%;
}

app-auth {
  display: flex;
  max-width: unset !important;
}

app-login {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

app-card-select-type-plan {
  app-button a {
    border-radius: 20px !important;
  }

  div.card-container:hover {
    app-button a {
      color: #fff !important;
      background: rgba(255, 255, 255, 0.1);
    }

    .card-text {
      color: #fff;
    }
  }

  @media screen and (max-width: 560px) {
    app-button a {
      color: #fff !important;
      background: rgba(255, 255, 255, 0.1) !important;
    }
  }
}

app-dialog.table {
  width: fit-content !important;
}

app-header {
  svg {
    vertical-align: middle !important;
  }
}

app-plan-simulation-popup {
  app-button > button.mat-accent {
    background-color: $dark-primary-color !important;
    min-width: 158px;
  }
}

.mat-focused .mat-form-field-required-marker {
  color: $default-primary-color;
}

::-webkit-scrollbar-track {
  background-color: rgb(0 0 0 / 4%);
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgb(0 0 0 / 14%);
}

.sale-continue-button {
  color: #fff;
  background-color: $default-secondary-color;
}

// .mat-checkbox-checked.mat-accent .mat-checkbox-background {
//   background-color: $default-secondary-color !important;
// }

span.mat-radio-label-content {
  color: $default-text-color;
}

.fill-button {
  color: $default-secondary-color;

  @media screen and (max-width: 540px) {
    font-size: 11px;
  }
}

app-broker-clients-defaulters,
app-broker-client-card-numbers,
app-broker-client-invoices {
  p {
    color: $default-text-color;
    border-bottom: 1px solid #dbdbdb;
  }
}

.broker-clients-loader-modal-container,
.broker-clients-loader-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  small {
    color: $default-primary-color;
  }
}

/* Início @media screen */
app-list-simulation {
  @media screen and (max-width: 560px) {
    th.mat-header-cell,
    .mat-cell {
      font-size: 12px;
    }
  }
}

app-button {
  @media screen and (max-width: 540px) {
    span.mat-button-wrapper {
      font-size: 12px;
    }

    button {
      width: fit-content !important;
    }
  }
}

app-input-age-range {
  @media screen and (max-width: 400px) {
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0;
    }
  }

  @media screen and (max-width: 560px) {
    mat-form-field .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-appearance-outline .mat-form-field-flex {
      padding-top: 10px;
    }

    .mat-form-field-flex {
      height: 48px;
    }

    .mat-form-field-infix {
      border: none;
    }
  }
}

@media screen and (max-width: 360px) {
  .mat-paginator-range-label {
    margin: 0 20px 0 0 !important;
  }
}

@media screen and (max-width: 560px) {
  div.mat-select-panel {
    margin-left: -27px;
  }
}

@media screen and (max-width: 1000px) {
  mat-dialog-container.mat-dialog-container {
    overflow-x: auto;
    width: 95vw;
    margin: 0 auto;
    max-width: fit-content;
  }

  // mat-form-field .mat-form-field-wrapper {
  //   padding-bottom: 10px;
  // }
}

app-plan-simulation-popup {
  @media screen and (max-width: 560px) {
    div.plans-to-simulation.show {
      max-height: fit-content;
    }
  }
}

app-plan-simulation-popup {
  @media screen and (max-width: 1000px) {
    div.plans-to-simulation.show {
      width: auto;
      left: 0;
      right: 0;
    }
  }
}

// @media screen and (max-width: 1000px) {
//   mat-error,
//   mat-form-field {
//     margin: 10px 0 !important;
//   }
// }

@media screen and (max-width: 540px) {
  .mat-tab-labels {
    flex-direction: column;
  }

  app-proponent-products {
    mat-card {
      width: 155px;
    }

    h5 {
      font-size: 0.9rem;
    }

    p,
    small {
      font-size: 0.8rem;
    }
  }
}

app-client-documents-input {
  width: fit-content;
}

app-proponent-steps {
  @media screen and (max-width: 400px) {
    mat-expansion-panel.proponent-products
      > div
      > div.mat-expansion-panel-body {
      padding: 0 0 24px 0;
    }

    app-proponent-products {
      button {
        margin-right: 5% !important;
      }

      p {
        text-align: center;
      }
    }

    mat-form-field {
      width: 95%;
      padding-left: 5%;
    }
  }
}

app-proposal-info-summary,
app-proposal-client-summary,
app-proposal-proponents-summary,
app-financial-data-summary {
  .summary-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media screen and (max-width: 820px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 540px) {
      grid-template-columns: 1fr;
    }
  }

  p {
    color: $default-gray;
    margin-bottom: 0.8rem;

    &:not(.summary-title) {
      font-size: 0.8rem;
    }
  }

  .summary-title {
    font-weight: 600;
    color: $default-text-color;
  }

  .summary-title,
  .summary-content {
    margin-bottom: 5px;
    margin-right: 10px;
  }

  .summary-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    @media screen and (min-width: 1030px) {
      position: absolute;
      right: 20px;
    }

    button {
      // color: #535efd;
      color: $default-text-color;
    }
  }

  h5 {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    color: $default-text-color;

    @media screen and (max-width: 540px) {
      padding-top: 15px;
    }
  }

  h6 {
    font-size: 1rem;
    font-weight: 600;
    color: $default-primary-color;
  }
}

.sale-previous-next-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  border-top: 1px solid #e1e1e1;
  padding-top: 10px;
  margin-top: 20px;

  button:not(.raised-button-secondary) {
    color: $default-gray;
  }
}

@media screen and (max-width: 540px) {
  .mat-vertical-content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 540px) {
  mat-panel-title {
    font-size: 13px;
  }
}

app-contract-and-product,
app-consent-form,
app-science-declaration,
app-proposal-acceptance {
  @media screen and (max-width: 540px) {
    span.mat-checkbox-layout,
    span.mat-radio-label-content,
    span.mat-checkbox-label {
      white-space: normal !important;
      text-align: start;
    }

    span.mat-checkbox-inner-container {
      margin-top: 5px;
    }
  }
}
