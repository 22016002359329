@import "/src/assets/scss/variables.scss";

.mat-form-field-outline {
  transition: 0.3s !important;
}

.mat-form-field-outline.mat-form-field-outline-thick {
  border-radius: 5px;
}

mat-form-field {
  &.mat-focused {
    .mat-form-field-outline {
      box-shadow: 0px 4px 5px -2px $default-gray;
    }
    &.ng-invalid.ng-touched {
      .mat-form-field-outline {
        box-shadow: 0px 4px 5px -2px $dark-danger;
      }
    }
  }

  &.ng-invalid.ng-touched .mat-icon.material-icons {
    color: $dark-danger;
  }

  &.ng-valid.ng-touched .mat-icon.material-icons {
    color: $dark-success;
  }

  mat-select {
    .mat-select-arrow {
      color: $dark-gray;
      width: 7px;
      height: 7px;
      border: 1.5px solid;
      transform: rotate(45deg) !important;
      border-left: 0 solid transparent;
      border-top: 0 solid transparent;
      margin: 0 4px;
    }
  }
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  color: rgba(0, 0, 0, 0.4);
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background-color: $dark-primary-color !important;
  background: $dark-primary-color !important;
}

mat-slide-toggle {
  .mat-slide-toggle-bar {
    width: 44px;
    height: 26px;
    border-radius: 13px;
  }

  .mat-slide-toggle-thumb-container {
    top: 3px;
    left: 4px;
  }

  &.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: white;
  }

  &.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $default-primary-color;
  }

  .mat-slide-toggle-bar {
    transform: scale(0.8);
  }

  .mat-slide-toggle-content {
    margin-left: -5px;
  }

  .mat-ripple-element {
    transform: scale(0.8) !important;
    margin: 4px 5px 5px 4px !important;
  }

  .mat-ripple-element:not(.mat-slide-toggle-persistent-ripple) {
    margin-left: -6px;
    margin-top: -6px;
  }
}

app-input-age-range {
  .mat-form-field-appearance-outline.mat-form-field-disabled
    .mat-form-field-label {
    color: black !important;
  }
  .mat-form-field-appearance-outline.mat-form-field-disabled
    .mat-form-field-outline {
    color: black !important;
  }
}

.mat-form-field-subscript-wrapper {
  margin-top: 0.3em !important;
}
