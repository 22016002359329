$lighter-primary-color: var(--lighterPrimaryColor);
$light-primary-color: var(--lightPrimaryColor);
$default-primary-color: var(--primaryColor);
$dark-primary-color: var(--darkPrimaryColor);
$darker-primary-color: var(--darkerPrimaryColor);

$lighter-secondary-color: var(--lighterSecondaryColor);
$light-secondary-color: var(--lightSecondaryColor);
$default-secondary-color: var(--secondaryColor);
$dark-secondary-color: var(--darkSecondaryColor);
$darker-secondary-color: var(--darkerSecondaryColor);

$light-gray: #bebebe;
$default-gray: #797979;
$dark-gray: #333333;

$default-text-color: #43425d;

$light-info: #8088f2;
$default-info: #3742f0;
$dark-info: #2b35bd;

$light-success: #7df0ad;
$default-success: #45ce7e;
$dark-success: #27b161;

$light-warning: #efd87f;
$default-warning: #f0cc3c;
$dark-warning: #f1c40f;

$light-danger: #f4a39b;
$default-danger: #ea7569;
$dark-danger: #e74c3c;
