@import "/src/assets/scss/variables.scss";

.custom-mat-stepper {
  &.mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:first-child)::before,
  &[dir="rtl"]
    .mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:last-child)::before,
  &.mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:last-child)::after,
  [dir="rtl"]
    .mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:first-child)::after {
    border-top-width: 3px !important;
    content: "" !important;
    width: calc(50% - 11px) !important;
  }

  :not(.mat-horizontal-stepper-header-container) {
    .mat-horizontal-stepper-header::before,
    .mat-horizontal-stepper-header::after,
    .mat-stepper-horizontal-line {
      border-top-color: $dark-secondary-color !important;
    }

    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit {
      background-color: $dark-secondary-color;
    }

    // :not(.mat-step-icon-state-done) {
    //   .mat-step-icon-state-number .mat-step-icon-content {
    //     font-weight: 600;
    //   }

    //   &:not(.mat-step-icon-selected).mat-step-icon {
    //     background: none !important;
    //     border: 3px solid $dark-secondary-color !important;
    //     margin-top: 1px !important;
    //   }
    // }
    // .mat-step-label-selected .mat-step-text-label {
    //   color: $dark-secondary-color !important;
    // }

    // :not(.mat-step-icon-state-done):not(.mat-step-icon-selected).mat-step-icon {
    //   color: $dark-gray;
    // }
  }

  .mat-horizontal-stepper-header-container {
    .mat-horizontal-stepper-header::before,
    .mat-horizontal-stepper-header::after,
    .mat-stepper-horizontal-line {
      border-top-color: $dark-primary-color !important;
    }

    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit {
      background-color: $dark-primary-color;
    }

    :not(.mat-step-icon-state-done) {
      .mat-step-icon-state-number .mat-step-icon-content {
        font-weight: 600;
      }

      &:not(.mat-step-icon-selected).mat-step-icon {
        background: none !important;
        border: 3px solid $dark-primary-color !important;
        margin-top: 1px !important;
      }
    }
    .mat-step-label-selected .mat-step-text-label {
      color: $dark-primary-color !important;
    }

    .mat-stepper-horizontal-line {
      border-top-width: 3px !important;
    }

    .mat-step-icon {
      transition: 0.3s;
    }

    .mat-step-icon.mat-step-icon-state-done {
      animation-timing-function: ease-in-out;
      animation-name: matStepSelectOutAnimation;
      animation-iteration-count: 1;
      animation-duration: 0.4s;
      animation-fill-mode: backwards;
    }

    .mat-step-icon.mat-step-icon-selected {
      animation-timing-function: ease-in-out;
      animation-name: matStepSelectAnimation;
      animation-iteration-count: 1;
      animation-duration: 0.4s;
      animation-fill-mode: forwards;
    }

    :not(.mat-step-icon-state-done):not(.mat-step-icon-selected).mat-step-icon {
      color: $default-secondary-color;
    }

    .mat-step-icon-state-done .mat-step-icon-content {
      display: block !important;
      margin-top: 2px;
    }

    .mat-step-text-label {
      color: $light-gray !important;
      font-weight: 500 !important;
      text-transform: uppercase !important;
    }
  }
}

@keyframes matStepSelectAnimation {
  0% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.6);
  }

  100% {
    transform: scale(1.3);
  }
}

@keyframes matStepSelectOutAnimation {
  0% {
    transform: scale(1.3) rotate(0);
  }

  100% {
    transform: scale(1) rotate(360deg);
  }
}
